<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Create Order')}}</button>
    </div>
    <div class="form-inner">
      <div class="order-infomration">
        <el-dialog :title="$t('Select Customer')" :visible.sync="displayCustomerForm" width="60%">
          <SelectCustomer @customerSelected="updateCustomer"/>
        </el-dialog>

        <el-dialog :title="$t('Select Product')" :visible.sync="displayProductForm" width="60%">
          <SelectProduct @productSelected="updateProducts"/>
        </el-dialog>

        <el-card class="box-card related-customer-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Customer information') }}</span>
          </div>
          <el-form class="related-customer-form" ref="related-customer-form" :model="form" label-width="150px" label-position="left">
            <div class="row">
              <el-button @click="activeSelectCustomer"> {{ $t('Select Customer') }} </el-button>
              <el-button @click="activeCreateCustomer"> {{ $t('Create Customer') }} </el-button>
            </div>
            <div class="row create-customer-form" v-if="createCustomer || this.form.customer">
              <div class="row" style="width: 80%">
                <el-form-item :label="$t('Name')" style="width: 100%">
                  <el-input :disabled="selectCustomer" v-model="form.customer.name"></el-input>
                </el-form-item>
              </div>
              <div class="row">
                <el-form-item class="email" :label="$t('Email')" style="max-width: 80%">
                  <el-input :disabled="selectCustomer" v-model="form.customer.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('Phone Number')" style="max-width: 80%">
                  <el-input :disabled="selectCustomer" v-model="form.customer.phone"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </el-card>

        <el-card class="box-card related-products-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Add Products') }}</span>
          </div>
          <el-form class="related-products-form" ref="related-products-form" :model="form" label-width="150px" label-position="left">

            <div class="row">
              <el-button @click="activeSelectProduct"> {{ $t('Select Product') }} </el-button>
            </div>

            <div class="row" v-if="form.products.length !== 0">
              <div class="selected-products-list">
                <div class="selected-product" v-for="(product, productIndex) in selectedProducts">
                  <div class="product-info">
                    <div class="product-image-wrapper">
                      <img class="product-image img-fluid" v-if="getProductImage(product.product_images)" :src="getProductImage(product.product_images)" />
                      <img class="product-image img-fluid" v-else src="@/assets/no-image.jpg" />
                    </div>
                    <div class="product-name" v-if="langcode === 'zh'">
                      {{ product.product_name_zh }}
                    </div>
                    <div class="product-name" v-else>
                      {{ product.product_name_en }}
                    </div>
                  </div>

                  <div class="product-detail">
                    <el-input class="option-number" v-model="form.products[productIndex].quantity" type="number" :placeholder="$t('Enter the item number')"></el-input>
                    <div class="product-amount">{{$t('Amount')}}: HK$ {{ getProductPrice(product, form.products[productIndex].quantity) }}</div>  
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </el-card>

    <el-card class="box-card delivery-card">
      <div slot="header" class="clearfix">
        <span>{{ $t('Delivery Information') }}</span>
      </div>
      <el-form class="related-delivery-form" ref="related-delivery-form" :model="form" label-width="200px" label-position="left">
        <div class="row" style="width: 100%">
          <el-form-item :label="$t('Delivery Method')" style="width: 100%">
            <el-select v-model="form.deliveryMethod" >
              <el-option :label="$t('Pick up in store')" value="Pick up in store">
              </el-option>
              <el-option :label="$t('Pay on delivery')" value="Pay on delivery">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-checkbox class="checkbox" @change="onCheckSame" v-model="sameWithCustomer">{{ $t('Same with Customer Information') }}</el-checkbox>
        <div class="row">
          <el-form-item :label="$t('Recipient Name')" style="width: 90%">
            <el-input v-model="form.recipientName" />
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item :label="$t('Recipient Phone Number')" style="width: 90%">
            <el-input v-model="form.recipientPhone" />
          </el-form-item>
        </div>
        <div class="row region-district" v-if="form.deliveryMethod !== 'Pick up in store'">
          <el-form-item :label="$t('Region')">
            <el-select v-model="form.region">
              <el-option :label="$t('Hong Kong Island')" value="Hong Kong Island">
              </el-option>
              <el-option :label="$t('Kowloon')" value="Kowloon">
              </el-option>
              <el-option :label="$t('New Territories')"value="New Territories">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('District')">
            <el-select v-model="form.district">
              <el-option v-for="district in districts[form.region]" :key="district" :label="district" :value="district" />
            </el-select>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item v-if="form.deliveryMethod !== 'Pick up in store'" :label="$t('Address')">
            <el-input v-model="form.recipientAddress" />
          </el-form-item>
        </div>
      </el-form>
     </el-card>

     <el-card class="box-card payment-card">
       <div slot="header" class="clearfix">
         <span>{{ $t('Payment Method') }}</span>
       </div>
       <el-select v-model="form.paymentMethod">
         <el-option :label="$t('Direct Bank Transfer')" value="Direct Bank Transfer">
         </el-option>
         <el-option :label="$t('FPS')" value="FPS">
         </el-option>
       </el-select>
     </el-card>

     <el-card class="box-card remark-card">
       <div slot="header" class="clearfix">
         <span>{{ $t('Order Remark') }}</span>
       </div>
       <el-input type="textarea" class="textarea" v-model="remark"></el-input>
     </el-card>
    </div>

    <div class="order-amount">
      <el-card class="box-card related-order-amount-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('Order Amount') }}</span>
        </div>
        <div class="amount-content">
          {{ $t('Total Amount')}}: HK$ {{ getTotalAmount() }}
        </div>
      </el-card>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import SelectCustomer from '@/components/order/SelectCustomer';
import SelectProduct from '@/components/order/SelectProduct';

export default {
  name: 'CreateOrderForm',
  components: {
    SelectCustomer,
    SelectProduct,
  },
  data(){
    //Default form value
    let form = {
      customerId: null,
      customer: null,
      products: [],
      deliveryMethod: '',
      paymentMethod: '',
      remark: '',
      recipientName: '',
      recipientPhone: '',
      recipientAddress: '',
      district: '',
      region: '',
    };

    return {
      form,
      displayCustomerForm: false,
      displayProductForm: false,
      selectCustomer: false,
      createCustomer: false,
      sameWithCustomer: false,
      selectedProducts: [],
      currentOption: '',
      totalAmount: 0,
      districts: {
        'Hong Kong Island': [
          this.$t('Central and Western'),
          this.$t('Eastern'),
          this.$t('Southern'),
          this.$t('Wan Chai'),
        ],
        'Kowloon': [
          this.$t('Kowloon City'),
          this.$t('Kwun Tong'),
          this.$t('Sham Shui Po'),
          this.$t('Wong Tai Sin'),
          this.$t('Yau Tsim Mong')
        ],
        'New Territories': [
          this.$t('Islands'),
          this.$t('Kwai Tsing'),
          this.$t('North'),
          this.$t('Sai Kung'),
          this.$t('Sha Tin'),
          this.$t('Tai Po'),
          this.$t('Tsuen Wan'),
          this.$t('Tuen Mun'),
          this.$t('Yuen Long'),
        ],
      },
    };
  },
  methods:{
    onCheckSame(){
      if(this.sameWithCustomer && this.form.customer){
        this.form.recipientName = this.form.customer.name;
        this.form.recipientPhone = this.form.customer.phone;
      }else{
        this.form.recipientName = '';
        this.form.recipientPhone = '';
      }
    },
    loadVariations(variationString){
      const variations = JSON.parse(variationString);
      return variations;
    },
    updateProducts(products){
      this.displayProductForm = false;
      this.selectedProducts = products;

      const productInfos = [];

      for(let i = 0; i < products.length; i++){
        const product = products[i];
        const productInfo = {
          id: product.id,
          variations: [],
          quantity: null,
        };
        productInfos.push(productInfo);
      }
      const existedProducts = this.form.products;
      if(existedProducts.length > 0){
        const newProducts = Common.findNonDuplicate(existedProducts, productInfos);
        this.form.products = existedProducts.concat(newProducts);
      }else{
        this.form.products = productInfos;
      }
    },
    updateCustomer(customer){
      this.form.customer = {
        'name': customer.customer_name,
        'email': customer.customer_email,
        'phone': customer.customer_phone,
      };
      this.form.customerId = customer.id;
      this.displayCustomerForm = false;
    },
    activeSelectProduct(){
      this.displayProductForm = true;
    },
    activeSelectCustomer(){
      //Clear the form customer first
      this.form.customer = null;
      this.displayCustomerForm = true;
      this.createCustomer = false;
      this.selectCustomer = true;
    },
    activeCreateCustomer(){
      this.form.customer = {
        'name': '',
        'email': '',
        'phone': '',
      };
      this.selectCustomer = false;
      this.createCustomer = true;
    },
    async save(){
      this.$emit('action', this.form);
    },
    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    getProductPrice(product, productQuantity){
      const productPrice = product['product_regular_price'];
      const productAmount = productPrice * productQuantity;
      return productAmount;
    },
    getOptionPrice(variationsString, option, optionQuantity){
      const variations = JSON.parse(variationsString);
      let optionInfo = null;
      for(let i = 0; i < variations.length; i++){
        const variation = variations[i];
        optionInfo = variation.options.find(function(item){
          if(option === item.option_name_en || option === item.option_name_zh){
            return true;
          }
        });
      }
      if(optionInfo){
        const optionPrice = optionInfo.option_price;
        const optionAmount = optionPrice * optionQuantity;
        return optionAmount;
      }else{
        return 0;
      }
    },
    getOptionInfo(product, variation, option){
      const fullProductInfos = this.selectedProducts;
      const productInfo = fullProductInfos.find(function(item){
        return product.id === item.id;
      });
      const variationInfo = JSON.parse(productInfo.product_variations).find(function(item){
        if(variation.variation_name_en === item.variation_name_en || variation.variation_name_zh === item.variation_name_zh){
          return true;
        }
      });
      const optionInfo = variationInfo.options.find(function(item){
        if(option === item.option_name_en || option === item.option_name_zh){
          return true;
        }
      });
      return optionInfo;
    },
    getTotalAmount(){
      let totalAmount = 0;
      const currentProducts = this.form.products;
      const fullProductInfos = this.selectedProducts;

      for(let i = 0; i < currentProducts.length; i++ ){
        const currentProduct = currentProducts[i];
        const productInfo = fullProductInfos.find(function(item){
          return currentProduct.id === item.id;
        });
        const productPrice = productInfo['product_regular_price'];
        const productAmount = productPrice * currentProduct.quantity;
        totalAmount += productAmount;
      }

      return totalAmount;
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.order{
  .action-button-wrapper{
    text-align: right;
    margin-bottom: 50px;
  }
  .form-wrapper{
    padding-left: 20px;
  }
  .related-customer-form, .related-delivery-form{
    padding-left: 20px;
  }
  .create-customer-form{
    margin-top: 50px;
    padding-left: 20px;

    .email{
      margin-right: 50px;
    }
  }
  .el-card__header{
    background-color: black;
    color: white;
  }

  .related-products-card{
    margin-top: 50px;
  }
  .related-products-form{
    padding-left: 20px;
  }

  .option-number{
    margin-top: 30px;
  }

  .selected-products-list{
    margin-top: 50px;
    .selected-product{
      display: flex;
      margin-bottom: 30px;
    }
    .product-image-wrapper{
      width: 110px;
      height: 110px;
      position: relative;
      overflow: hidden;
      .product-image{
        overflow: hidden;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .product-info{
      margin-right: 30px;
    }
  }
  .delivery-card{
    margin-top: 50px;
    .checkbox{
      position: relative;
      right: 15px;
      bottom: 10px;
    }
  }
  .region-district{
    .el-form-item{
      margin-right: 50px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .remark-card{
    margin-top: 50px;
    .el-textarea__inner{
      height: 100px !important;
    }
  }

  .payment-card{
    margin-top: 50px;
  }

  .order-infomration{
    margin-right: 50px;
  }
  .order-amount{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
  }
  .product-amount{
    margin-top: 20px;
  }
}
</style>
